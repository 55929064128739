export const en = {
   header: {
      nav: {
         home: 'Home',
         solutions: 'Solutions',
         pricing: 'Pricing',
         about: 'About',
         projects: 'Projects',
         resume: 'Resume'
      }
   },
   footer: {
      social: {
         title: 'FIND ME ON',
         subtitle: 'Feel free to connect with me'
      },
      resume: {
         download: 'Download CV'
      },
      author:"Designed and Developped by ",
      tool: "Made with ❤ using React",
   },
   home: {
      meta:{
         title:"David Daillère - Web Developer",
         description:"Freelance Web Developer specializing in custom code (HTML, CSS, JavaScript, React, etc.) and comprehensive WordPress solutions. Whether you require a website for booking management, an online shop, a simple business page, or a more complex application, I'm here to bring your project to life. Let's turn your ideas into a digital reality! 💻🚀 #WebDevelopment #Freelancer #CodingExpert",
      },
      title: 'WEB DEVELOPER',
      description: `Welcome to my creative digital space!<br>
      Feel free to browse, be inspired, and get in touch.<br>
      Together, let's create the future!<br>`,
      intro: {
         title: "Freelance Web Developer:",
         subtitle: "As a freelance web developer, I specialize in website creation and application programming. I will guide you from the conceptualization to the hands-on use of your website. My mission is to develop tailor-made solutions, whether you require a simple webpage, a complete online store, or the integration of a booking System.",
         title2: "What I Offer:",
         sectionTitle1: "Customized Design:",
         section1: "Together, we will create the tool that best suits your needs, taking into consideration your objectives and vision.",
         sectionTitle2: "Technical Expertise:",
         section2: "Proficient in a broad spectrum of programming languages and technologies, I am capable of translating your ideas into functional solutions.",
         sectionTitle3: "Close Collaboration:",
         section3: "I believe in a collaborative approach, working closely with my clients throughout the entire process to ensure that not only are their expectations met, but exceeded.",
      },
      expertise: {
         title: 'Expertise',
         itemTitle1: "Web Deployment",
         itemText1: "Simplified Online Setup: Domain name, hosting, redirects—I take care of it all!",
         itemTitle2: "SEO",
         itemText2: "Increase Your Online Visibility: Website optimized for search engines (Google, Bing, etc.).",
         itemTitle3: "Emails",
         itemText3: "Custom email addresses with your domain. Implementation of a contact form on your site to streamline communication with your clients.",
         itemTitle4: "Responsive Design",
         itemText4: "Responsive to All Screens: Computers, Tablets, Phones.",
         itemTitle5: "Calendars",
         itemText5: "Manage appointments and bookings using a synchronized calendar system with real-time availability updates.",
         itemTitle6: "Accessibility",
         itemText6: "Because everyone should be able to have access to your website, I adhere to accessibility standards for all.",
      },
      solutions: {
         title1: "Hotels & Vacation Rentals",
         text1: "Comprehensive solutions for your rentals: calendar synchronization, user-friendly reservation system, online payment.",
         title2: "Online Shop",
         text2: "Expand your customer base by selling your products online.",
         title3: "Classic Website",
         text3: "A personal website for your business, organization, or blog.",
         title4: "Custom Codes",
         text4: "For unique solutions, I write codes from scratch, offering complete customization to meet your specific needs.",
      }
   },
   about: {
      title: 'ABOUT',
      subtitle:"ME",
      description: "Find out about my background, my interests, as well as my experiences and certificates. Explore the different facets of my professional and personal journey.",
      hobbies: {
         title:"Hobbies",
         trekking: 'Trekking',
         photography: 'Photography',
         surfing: 'Surfing',
         reading: 'Reading',
         theater: 'Performing Art'
      },
      testimonials_title:'Testimonials',
      book:"Background",
      tools:"Skills",
      resume: "Resume",
      thanks:"Thank you for visiting my website! I look forward to learning more about your project. Do not hesitate to contact me by email or telephone. Looking forward to chatting with you!",
      testimonials: [
         {
            author: 'Garfield Vaughn - UppaTop',
            content: [
               `David collaborated with us on a project for approximately four months. His attention to detail and the quality of his work were outstanding. We particularly appreciated his ability to refine his processes for maximum efficiency. 
            His dedication to learning and his willingness to work collaboratively with all team members were commendable, contributing to a harmonious team environment. 
            `,
               `David was prompt in attending all team calls and demonstrated clear and precise communication regarding his commitments and deliverables. He proactively addressed any challenges he faced, ensuring expectations were realigned as needed. I would not hesitate to engage David for future projects and can personally vouch for his excellence and professionalism.`,
            ],
         },
         {
            author: 'Brigitte - Ô Gîte Bleu',
            content: [
               `David built the website for Ô Gîte Bleu, and I am extremely pleased with his work. Thanks to his expertise, managing my reservations is now incredibly easy. The calendars are automatically synchronized with other listings (Booking.com, Airbnb, etc.). He also set up a payment portal and customized automatic emails and contracts, greatly simplifying coordination.            
            `,
               `His professionalism is remarkable, and he exceeded all my expectations. I highly recommend him!`,
            ],
         }
      ],

   },
   projects: {
      title: 'Projects',
      subtitle:'I Worked On',
      description: 'Explore my recent work to get an overview of my skills and diversity. Each project is a unique story of creativity and expertise. Find out how I turn ideas into real results.',
      recentWork: {
         uppaTop: {
            company: 'UppaTop',
            title: 'Insurance Contract Automation',
            text: `Conversion of over 300 designs into Responsive Web Pages. Create page and components templates for better efficiency. Use of Html and CSS, with great attention to detail.,
            Quick analysis of html structure and effective CSS styling.`,
            features: [],
         },
         ignite: {
            company: 'Ignite',
            title: 'online shop',
            text: `An e-commerce platform dedicated to candles, offering an optimal purchasing experience throughout Sri Lanka.`,
            features: ['Advanced Navigation Menu',
               'Search by Keywords or Product',
               'Product Catalog',
               'Secure Online Payment',
               'Admin page to manage products, sales, inventory, and more.',
            ],
         },
         giteBleu: {
            company: 'Ô Gîte Bleu',
            title: 'Holiday homes',
            text: `A vacation rental website for 2 Cottages, with a reservation system platform.`,
            features: [
               'Multilingual site',
               'multilingual SEO',
               'Availability calendar.',
               'Synchronization every 10 minutes with booking.com airbnb etc.',
               'Online reservation and payment.',
               'An administration page to easily manage reservations.'],
         },
      },
      projects: {
         reddit: {
            company:'Reddit Lite',
            title: 'Single Page Apllication (SPA)',
            text: 'RedditLite: A simplified version of Reddit, where you can search, explore, and read articles. search content by keywords or categories, preview results, and enjoy a responsive experience with this Single Page Application',
            features: [
               'Single Page Application (React - Redux)',
               'See comments & repplies,',
               'advanced search bar',
            ],
         },
         booking: {
            company:'Booking Management - The App',
            title: 'A Booking Management System',
            text: 'A simple desktop app to help short-term rental home owners manage their business. Record reservations, Create contracts, invoices, e-mails and customer logbook automatically, Access statistics and turnover.',
            features: [],
         },
         gite: {
            company:'Booking Management - The Website',
            title: 'A classic website',
            text: 'Visit the website to explore my project: a desktop app I created to simplify vacation rental management.',
            features: [],
         },
         pho: {
            company:'Pho Restaurant',
            title: 'Restaurants Menu',
            text: `A Simple and Enjoyable Menu Page
                   Select your meal with ease and pleasure.`,
            features: [],
         },
      },
   },
   resume: {
      title: 'RESUME',
      description: 'Access my certifications, and explore my skills and professional experience...',
      download: 'Download Resume'
   },
   solutions: {
      meta:{
         title:"Building and Designing Your Website",
         description:"Affordable solutions for your website: online payment system, channel manager, calendar synchronization, e-commerce, professional page, and much more. Opt for features tailored to your specific needs. Transform your online presence. 💻🌐 #WebSolutions #ECommerce #OnlineManagement",
      },
      title: 'Solutions',
      subtitle: 'to your Needs',
      description: "I provide a range of solutions, whether you require a website for your hotel or vacation rental, aim to sell products online, prefer a classic website, or opt for custom code. My main objective is to tailor solutions to your unique needs in the most effective manner.",
      hotel: {
         title: "Hotel Solutions",
         description: "a website  integrated with a comprehensive booking management system. Showcase room availability, easy reservation process, and online payments. Additionally, benefit from a dedicated admin page to oversee all reservations. Synchronize your properties across various calendars and booking operators such as Airbnb, Booking.com, Google Calendar, and more.",
         section1: {
            title: "Website",
            subtitle1: "Deployment",
            description1: "Streamline the launch of your site: domain name purchase, hosting, and redirection.",
            subtitle2: "SEO",
            description2: "Boost your site's visibility, targeting search engines like Google, Bing, and more. I optimize your online presence to reach a broader audience.",
            subtitle3: "Multilingual",
            description3: "Expand your audience by offering a multilingual site. I configure and optimize your site for a seamless user experience in different languages",
            subtitle4: "Responsive Design",
            description4: "Provide an outstanding user experience across all devices with a responsive design. Your site seamlessly adapts to desktop, tablet, and mobile screens.",
            subtitle5: "Acessibility",
            description5: "Prioritize inclusivity with a site accessible to all. I ensure your site is designed following accessibility standards to provide a fair user experience.",
         },
         section2: {
            title: "Booking System",
            subtitle1: "Easy Management",
            description1: "Manage your reservations from your website admin page. Check the Calendar for a quick glimpse of your bookings, or the reservation table to view details and manage all bookings.",
            subtitle2: "Sync Calendar",
            description2: "I Synchronize your bookings with websites such as AirBnB, Google Cal, Homeaway, VRBO, booking.com.",
            subtitle3: "Channel Manager",
            description3: "A Channel Manager provides a centralized solution for managing all your online listings (Booking.com, Airbnb, etc.) from one place! I advise you on the different available solutions, install a new channel manager, or synchronize with the one you already have.",
            subtitle4: "Emails, Invoices & Contracts",
            description4: "Automated emails with prepared templates and automatic invoices and contracts.",
            subtitle5: "Flexible Rate Structures",
            description5: "You can define any type of rates: discount, coupon, tax, midweek, weekend, seasons ect.",
         },
         section3: {
            title: "Payment",
            subtitle1: "Payment Gateway",
            description1: "Streamline transactions with the integration of a payment gateway directly linked to your bank account.",
            subtitle2: "Refunds",
            description2: "Refund your customers simply in the event of cancellation.",
            subtitle3: "Means of Payment.",
            description3: "Different payment options (deposit or full amount) and different payment methods - online (by credit card or PayPal) or offline (bank transfer, on arrival...)",
            subtitle4: "",
            description4: "",
         },
         section4: {
            title: "Friendly Customer Interface",
            subtitle1: "Smooth Booking Process",
            description1: "Smooth booking process, from accommodation search to booking confirmation",
            subtitle2: "Options",
            description2: "Offer additional services to your customers that can be added to their reservation",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
      },
      shop: {
         title: "Online Shop",
         description: "An e-commerce platform crafted with WooCommerce, designed to sell and manage a diverse range of products online. Whether it's physical goods, services, clickable links, or digital products, a versatile and comprehensive system for your business needs.",
         section1: {
            title: "",
            subtitle1: "",
            description1: "",
            subtitle2: "",
            description2: "",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
         section2: {
            title: "Woo-Commerce",
            subtitle1: "Set-Up",
            description1: "Design, process, currency, tax, delivery price by region etc...",
            subtitle2: "Products Set-Up",
            description2: "Product type, description, price, variation (color, size, etc.)",
            subtitle3: "Menu",
            description3: "Advanced and elegant menu for a more pleasant experience",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
         section3: {
            title: "Payment",
            subtitle1: "Payment Gateway",
            description1: "Streamline transactions with the integration of a payment gateway directly linked to your bank account.",
            subtitle2: "Refunds",
            description2: "Refund your customers easily.",
            subtitle3: "Means of Payment.",
            description3: "Different payment options (credit card, PayPal ect).",
            subtitle4: "Flexible Rate Structures",
            description4: "Set any type of rates: discount, coupon, tax, delivery ect.",
            subtitle5: "",
            description5: "",
         },
         section4: {
            title: "",
            subtitle1: "",
            description1: "",
            subtitle2: "",
            description2: "",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
      },
      website: {
         title: "Classic Website",
         description: "I am here to create the website that suits your needs at an unbeatable price. You choose the options you require, and you can upgrade your site whenever you want.",
         section1: {
            title: "Set-Up",
            subtitle1: "Deployment",
            description1: "Streamline the launch of your site: domain name purchase, hosting, and redirection.",
            subtitle2: "SEO",
            description2: "Boost your site's visibility, targeting search engines like Google, Bing, and more. I optimize your online presence to reach a broader audience.",
            subtitle3: "Multilingual",
            description3: "Expand your audience by offering a multilingual site. I configure and optimize your site for a seamless user experience in different languages",
            subtitle4: "Acessibility",
            description4: "Prioritize inclusivity with a site accessible to all. I ensure your site is designed following accessibility standards to provide a fair user experience.",
         },
         section2: {
            title: "Design",
            subtitle1: "Fit Your Style",
            description1: "I am here to ensure that the style of your website aligns seamlessly with that of your brand. If you haven't established a specific style yet, we will collaboratively explore the colors, typography, and overall aesthetic that you envision.",
            subtitle2: "Responsive Design",
            description2: "Provide an outstanding user experience across all devices with a responsive design. Your site seamlessly adapts to desktop, tablet, and mobile screens.",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
         section3: {
            title: "Custom Set-Up",
            subtitle1: "Evolve at Your Own Pace",
            description1: "You have the flexibility to incorporate, at any time, the essential solutions for your business. Whether it's a reservation system to efficiently manage appointments, an online store to expand your commercial presence, or even a blog to share your stories, I am here to customize your online experience according to your specific needs. Together, we evolve your site to perfectly align with your aspirations.",

         },
         section4: {
            title: "Your Vision",
            subtitle1: "Tell Me About Your Needs",
            description1: "I listen to you and advise you to meet your needs. My goal is to understand in depth what you are looking for, your goals and the vision you have for your website. Do not hesitate to share your ideas, aspirations and expectations.",
            subtitle2: "",
            description2: "",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
      },
      custom: {
         title: "Codes personnalisés",
         description: "As a Front-End Developer, I possess a thorough mastery of the foundational elements of the web, with specific expertise in the following technologies",
         section1: {
            title: "Front-End Developer",
            subtitle1: "HTML, CSS, JavaScript",
            description1: "My skills extend to crafting dynamic and interactive web pages using the fundamental languages of the web.",
            subtitle2: "Single Page Applications (SPA)",
            description2: "I have in-depth experience in building Single Page Applications using modern frameworks like React, along with proficient navigation management using React Router.",
            subtitle3: "Redux",
            description3: "Proficient in state management with Redux, ensuring smooth data flow within the application.",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
         section2: {
            title: "Development Tools",
            subtitle1: "GitHub",
            description1: "Advanced use of GitHub for efficient version control and collaboration on projects.",
            subtitle2: "VS Code",
            description2: "As the primary code editor, I work efficiently with Visual Studio Code for rapid and effective development.",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
         section3: {
            title: "Contact Me",
            subtitle1: "",
            description1: "If you have a project in mind, feel free to reach out. I'm open to discussing your ideas, understanding your needs, and finding the best solution to bring your vision to life on the web.",
            subtitle2: "",
            description2: "",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
         section4: {
            title: "",
            subtitle1: "",
            description1: "",
            subtitle2: "",
            description2: "",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
      }
   }


}