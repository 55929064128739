export const fr = {
   header: {
      nav: {
         home: 'Accueil',
         solutions: 'Solutions',
         pricing: 'Tarifs',
         about: 'À Propos',
         projects: 'Projets',
         resume: 'CV'
      }
   },
   footer: {
      social: {
         title: 'RÉSEAUX SOCIAUX',
         subtitle: 'Connectons-nous pour créer ensemble'
      },
      author:"Conçu et développé par",
      tool: "Réalisé avec ❤ - En utilisant React",

   },
   home: {
      meta:{
         title:"David Daillère - Développeur web",
         description:"Développeur Web indépendant spécialisé en front-end (HTML, CSS, JavaScript, React, etc.) et en solutions complètes avec WordPress. Besoin d'un site pour gérer vos réservations, d'une boutique en ligne, d'une page entreprise simple, ou d'une application plus complexe ? Je suis là pour concrétiser votre projet. Transformons vos idées en réalité numérique ! 💻🚀 #DéveloppementWeb #Freelance #ExpertEnCodage",
      },
      title: 'DÉVELOPPEUR WEB',
      description: `Bienvenue dans mon espace numérique créatif ! <br>
      N'hésitez pas à naviguer, à vous inspirer et à entrer en contact.<br>
      Ensemble, créons l'avenir ! <br>
      `,
      intro: {
         title: "Je construis votre site internet:",
         subtitle: "En tant que développeur web indépendant, je me spécialise dans la conception de sites internet et la programmation d'applications. Je vous guide tout au long du processus, de la phase de conception à la prise en main de votre outil. Ma mission consiste à élaborer des solutions personnalisées, que vous souhaitiez une page internet simple, une boutique en ligne complète, ou une solution pour gérer vos rendez-vous et réservations, etc.",
         title2: "Ce que je propose:",
         sectionTitle1: "Conception Personnalisée:",
         section1: "Ensemble, nous créerons l'outil le plus adapté à vos besoins, en tenant compte de vos objectifs et de votre vision.",
         sectionTitle2: "Expertise Technique:",
         section2: "Maîtrisant un large éventail de langages de programmation et de technologies, je suis capable de transformer vos idées en solutions fonctionnelles.",
         sectionTitle3: "Collaboration Étroite:",
         section3: "Je crois en une approche collaborative, travaillant en étroite collaboration avec mes clients tout au long du processus pour m'assurer que leurs attentes sont non seulement satisfaites, mais dépassées.",

      },
      expertise: {
         title: 'Expertise',
         itemTitle1: "Mise en Ligne",
         itemText1: "Mise en Ligne Simplifiée: Nom de domaine, hébergement, redirections, je m'occupe de tout!",
         itemTitle2: "Référencement",
         itemText2: "Boostez votre visibilité en ligne: site optimisé pour les moteurs de recherche (Google, Bing, etc.).",
         itemTitle3: "Emails",
         itemText3: "Adresses email personnalisées et intégration d'un formulaire de contact sur votre site pour simplifier la communication avec vos clients.",
         itemTitle4: "Design Adaptatif",
         itemText4: "Adaptation à tous les écrans: Ordinateurs, tablettes, téléphones.",
         itemTitle5: "Calendriers",
         itemText5: "Calendrier de disponibilité pour vos rendez-vous ou réservations, avec synchronisation possible avec vos autres calendriers ou annonces en ligne.",
         itemTitle6: "Accessibilité",
         itemText6: "Parce que tout le monde devrait avoir accès à votre site, je respecte les normes d'accessibilité pour tous",
      },
      solutions: {
         title1: "Hôtels & Locations Saisonnières",
         text1: "Solutions complètes pour vos locations : synchronisation des calendriers, système de réservation simple, paiement en ligne.",
         title2: "Magasin En Ligne",
         text2: "Élargissez votre clientèle en vendant vos produits en ligne.",
         title3: "Site Internet Classique",
         text3: "Un site internet élégant et de qualité pour votre entreprise, organisation ou blog.",
         title4: "Solutions Sur Mesure",
         text4: "Pour des solutions uniques, j'écris vos codes à partir de zéro, offrant une personnalisation complète pour répondre à vos besoins spécifiques.",
      }
   },
   about: {
      title: 'À PROPOS',
      subtitle: 'DE',
      description: "Je vous dévoile mon parcours, mes centres d'intérêt, ainsi que  mon curriculum vitae et mes diplômes. Explorez les différentes facettes de mon parcours professionnel et personnel.",
      hobbies: {
         title:"Loisirs",
         trekking: 'Randonnée',
         photography: 'Photographie',
         surfing: 'Surf',
         reading: 'Lecture',
         theater: 'Spectacle Vivant',
      },
      book:"Mon Parcours",
      tools:"Compétences",
      resume: "CV",
      thanks:"Merci infiniment pour votre visite ! J'ai hâte d'en apprendre davantage sur votre projet. N'hésitez pas à me contacter par email ou téléphone. Au plaisir d'échanger avec vous !",
      testimonials_title:'Témoignages',
      testimonials: [
         {
            author: 'Garfield Vaughn - UppaTop',
            content: [
               `David a collaboré avec nous sur un projet pendant environ quatre mois. Son souci du détail et la qualité de son travail étaient remarquables. Nous avons particulièrement apprécié sa capacité à affiner ses processus pour une efficacité maximale.
               Son dévouement à l'apprentissage et sa volonté de travailler en collaboration avec tous les membres de l'équipe étaient louables, contribuant à un environnement d'équipe harmonieux. 
            `,
               `David a répondu présent à toutes les réunion de l'équipe et a fait preuve d'une communication claire et précise concernant ses engagements. Il a relevé de manière proactive tous les défis auxquels il était confronté, en veillant à ce que les attentes soient réalignées selon les besoins. Je n'hésiterais pas à embaucher David pour de futurs projets et je peux personnellement témoigner de son excellence et de son professionnalisme.`,
            ],
         },
         {
            author: 'Brigitte - Ô Gîte Bleu',
            content: [
               `David a créé le site internet pour Ô Gîte Bleu, et je suis extrêmement satisfaite de son travail. Grâce à son expertise, la gestion de mes réservations est désormais incroyablement facile. Les calendriers sont automatiquement synchronisés avec d'autres annonces (Booking.com, Airbnb, etc.). Il a également installé un portail de paiement et personnalisé les emails automatiques ainsi que les contrats, ce qui simplifie grandement la coordination.              
            `,
               `Son professionnalisme est remarquable, et il a surpassé toutes mes attentes. Je le recommande vivement !`,
            ],
         }
      ],
   },
   projects: {
      title: 'Découvrez ',
      subtitle: 'Mon Travail',
      description: "Explorez mes récents projets professionnels et personnels pour avoir un aperçu rapide de mes compétences et de ma diversité. Chaque projet est une histoire unique de créativité et d'expertise. Découvrez comment je transforme des idées en résultats concrets.",
      recentWork: {
         uppaTop: {
            company: 'UppaTop',
            title: `Automatisation de Contrats d'Assurance`,
            text: `Conversion de plus de 300 designs en pages Web réactives. Créez des modèles de pages et des composants pour une meilleure efficacité. Grande attention aux détails. Analyse rapide de la structure HTML et style CSS efficace.`,
            features:[],
         },
         ignite: {
            company: 'Ignite',
            title: 'Magasin en Ligne',
            text: `Une plateforme e-commerce dédiée à la vente de bougies, proposant une expérience d'achat optimale à travers tout le Sri Lanka.`,
            features:[
               'Menu de Navigation Avancé ',
               'Recherche par Mots-Clés ou par Produit ',
               'Catalogue des Produits',
               'Présentation Détaillée de Chaque Produit',
               'Paiement en Ligne Sécurisé',
               'Page Admin pour gérer les produits, les ventes, les stocks, et plus encore.',
            ],
         },
         giteBleu: {
            company: 'Ô Gîte Bleu',
            title: 'Location de vacances',
            text: `Un site internet pour la location de 2 Gîtes, avec une plateforme de gestion des réservations pour gérer les locations facilement `,
            features: [
               'Site internet et référencement multilingue',
               'Calendrier de disponibilité.',
               'Synchronisation toutes les 10 min avec booking.com airbnb etc.',
               'Réservation et paiement en ligne.',
               'Une page admin pour gérer facilement les Réservations.',
            ]
         },
      },
      projects: {
         reddit: {
            company:'Reddit Lite',
            title: 'Application à Page Unique (SPA)',
            text: "une version simplifiée de Reddit, où vous pouvez rechercher, explorer et lire des articles. recherchez du contenu par mots-clés ou catégories, prévisualisez les résultats et profitez d'une expérience réactive avec cette application à page unique",
            features:[
               'Application à page unique (React - Redux)', 
               'Voir les commentaires et les réponses', 
               'barre de recherche avancée',
            ],
         },
         booking: {
            company:"Gérer Mon Gîte - L'application ",
            title: "Gestion de gîtes et chambre d'hôtes ",
            text: "Une application simple pour aider les propriétaires de gîtes à gérer leur activité. Enregistrer les réservations, Créer automatiquement les contrats, factures, emails et carnet client, Accéder aux statistiques et au chiffre d'affaires.",
            features:[],
         },
         gite: {
            company:"Gérer Mon Gîte - le site internet ",
            title: 'Site internet classique',
            text: "Un site internet pour présenter un de mes projets : une application qui simplifie la gestion des locations de gîtes.",
            features:[],
         },
         pho: {
            company: 'Restaurant Vietnamien',
            title: 'Menu pour restaurants',
            text: "Un menu de restaurant simple et agréable. Choisissez votre repas avec facilité et plaisir.",
            features:[],
         },
      },
   },
   resume: {
      title: 'CV',
      description: 'Accédez à mes certifications ou explorez mes compétences et expérience professionnelle...',
      download: 'Télécharger CV'
   },
   solutions: {
      meta:{
         title:"Conception de Votre Site Internet ou Application - David Daillère",
         description:"Solutions sur mesure et à petit prix pour votre site internet: système de paiement en ligne, channel manager, synchronisation de calendrier, e-commerce, page professionnelle, et bien plus encore. Optez pour des fonctionnalités adaptées à vos besoins spécifiques. Transformez votre présence en ligne avec mes services personnalisés. 💻🌐 #WebSolutions #ECommerce #OnlineManagement",
      },
      title: 'Solutions ',
      subtitle: 'Sur Mesure',
      description: 'Je propose différentes solutions, que ce soit pour votre hôtel ou vos locations saisonnières, la vente en ligne de vos produits, un site internet classique pour votre entreprise, association ou blog, ou des codes personnalisés. Mon objectif premier est de répondre de manière optimale à vos besoins uniques.',
      hotel: {
         title: "Hôtel",
         description: "Offrez à votre entreprise une présence en ligne exceptionnelle avec un site web doté d'un calendrier de disponibilité intuitif et d'un processus de réservation simplifié. Pour faciliter la gestion de votre activité, bénéficiez d'une page d'administration dédiée qui vous permet de gérer aisément l'ensemble de vos réservations. Simplifiez votre quotidien et offrez à vos clients une expérience de réservation fluide et efficace.",
         section1: {
            title: "Site Internet",
            subtitle1: "Déploiement",
            description1: "Processus de lancement simplifié: achat de nom de domaine, hébergement, redirection de votre ancien site internet ect.",
            subtitle2: "Référencement",
            description2: "J'optimise votre présence en ligne pour atteindre un public plus large, couvrant des moteurs de recherche tels que Google, Bing, et bien plus encore.",
            subtitle3: "Multilingue",
            description3: "Élargissez votre audience en offrant un site multilingue. Je configure et optimise votre site pour une expérience utilisateur fluide En Français et en Anglais.",
            subtitle4: "Design Adapté",
            description4: "Votre site s'adapte parfaitement aux écrans de bureau, tablettes, et smartphones.",
            subtitle5: "Accessibilité",
            description5: "Je m'assure que votre site est conçu selon les normes d'accessibilité numérique pour garantir une expérience utilisateur équitable.",
         },
         section2: {
            title: "Système de Réservation",
            subtitle1: "Gestion de Réservations Simplifiée",
            description1: "Gérez vos réservations depuis la page d'administration de votre site Web. Vous pouvez voir le calendrier pour un aperçu rapide de vos réservations, ou le tableau pour afficher les détails et gérer vos résa.",
            subtitle2: "Synchronisation de vos calendriers",
            description2: "Synchronisez vos réservations avec des sites tels qu'AirBnB, Google Calendar, Booking.com, et bien d'autres",
            subtitle3: "Channel Manager",
            description3: "Un Channel Manager offre une solution centralisée pour gérer toutes vos annonces en ligne (Booking.com, Airbnb, etc.) à partir d'un seul endroit ! Je vous conseille sur les différentes solutions disponibles, j'installe un nouveau channel manager, ou je me synchronise avec celui que vous avez déjà.",
            subtitle4: "Automatisation des Emails, Factures et Contrats",
            description4: "Emails automatisés avec des modèles préparés, et génération automatique de factures et contrats.",
            subtitle5: "",
            description5: "",
         },
         section3: {
            title: "Paiement",
            subtitle1: "Paiement en ligne",
            description1: "Facilitez les transactions avec l'intégration d'un portail de paiement directement lié à votre compte bancaire.",
            subtitle2: "Tarification Avancé",
            description2: "Règles de réservation qui vous permet de définir tout type de tarification: remise, coupon, tax, midweek, weekend, saisons ect.",
            subtitle3: "Remboursement",
            description3: "Remboursez vos clients simplement en cas d'annulation.",
            subtitle4: "Moyens de Paiement",
            description4: "Différentes options de paiement (dépôt ou montant total) et différentes méthodes de paiement - en ligne (par carte de crédit ou PayPal) ou hors ligne (virement bancaire, à l'arrivée...)",
            subtitle5: "",
            description5: "",
         },
         section4: {
            title: "Interface client conviviale",
            subtitle1: "Réservation Simple",
            description1: "Processus de réservation fluide, de la recherche d'hébergement à la confirmation de réservation",
            subtitle2: "Options",
            description2: "Proposez des services supplémentaires à vos clients qui peuvent être ajoutés à leur réservation",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
      },
      shop: {
         title: "Magasin En ligne",
         description: "Une plateforme e-commerce créée avec WooCommerce pour vendre et gérer tout type de produit en ligne, que ce soit des biens physiques, des services, des liens cliquables ou des produits numériques. Approche polyvalente et complète pour répondre à vos besoins commerciaux.",
         section1: {
            title: "",
            subtitle1: "",
            description1: "",
            subtitle2: "",
            description2: "",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
         section2: {
            title: "Woo-Commerce",
            subtitle1: "Mise en place ",
            description1: "Design, procédé, devise, tax, prix de livraison par région ect...",
            subtitle2: "Création de produits.",
            description2: "Type de produit, description, prix, variation (couleur, taille, etc.)",
            subtitle3: "Menu et Navigation",
            description3: "Menu avancé et élégant pour une expérience plus agréable",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
         section3: {
            title: "Paiement",
            subtitle1: "Paiement en ligne",
            description1: "Facilitez les transactions avec l'intégration d'un portail de paiement directement lié à votre compte bancaire.",
            subtitle2: "Tarification Avancé",
            description2: "Différents types de tarification : taxe, livraison, remise, coupon, etc.",
            subtitle3: "Remboursement",
            description3: "Remboursez vos clients simplement en cas de besoin.",
            subtitle4: "Moyens de Paiement",
            description4: "Différentes options de paiement (carte de crédit, PayPal ect)",
            subtitle5: "",
            description5: "",
         },
         section4: {
            title: "",
            subtitle1: "",
            description1: "",
            subtitle2: "",
            description2: "",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
      },
      website: {
         title: "Site Internet",
         description: "Je suis à votre écoute pour créer le site qui vous convient à un prix imbattable. Vous choisissez les options dont vous avez besoin, et vous pouvez faire évoluer votre site quand vous le souhaitez.",
         section1: {
            title: "Mise en Place",
            subtitle1: "Déploiement",
            description1: "Processus de lancement simplifié: achat de nom de domaine, hébergement, redirection de votre ancien site internet ect.",
            subtitle2: "Référencement",
            description2: "J'optimise votre présence en ligne pour atteindre un public plus large, couvrant des moteurs de recherche tels que Google, Bing, et bien plus encore.",
            subtitle3: "Multilingue",
            description3: "Élargissez votre audience en offrant un site multilingue. Je configure et optimise votre site pour une expérience utilisateur fluide En Français et en Anglais.",
            subtitle4: "Accessibilité",
            description4: "Je m'assure que votre site est conçu selon les normes d'accessibilité numérique pour garantir une expérience utilisateur équitable.",
         },
         section2: {
            title: "Design",
            subtitle1: "Style Adapté à votre marque",
            description1: "Je suis à votre écoute pour que le style de votre site reflète parfaitement celui de votre marque. Si vous n'avez pas encore défini de style, nous étudierons ensemble les couleurs, la typographie et le style général que vous souhaitez adopter.",
            subtitle2: "Design Adapté",
            description2: "Votre site s'adapte parfaitement aux écrans de bureau, tablettes, et smartphones.",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
         section3: {
            title: "Personnalisation",
            subtitle1: "Évoluez au Rythme de Votre Activité",
            description1: "Vous avez la liberté d'intégrer, à tout moment, les solutions essentielles à votre activité. Que ce soit un système de réservation pour gérer efficacement vos rendez-vous ou réservations, une boutique en ligne pour étendre votre présence commerciale, ou même un blog pour partager vos récits, je suis là pour personnaliser votre expérience en ligne en fonction de vos besoins spécifiques. Ensemble, nous faisons évoluer votre site pour qu'il reflète parfaitement vos aspirations.",
         },
         section4: {
            title: "Votre Vision",
            subtitle1: "Parlez-Moi de Vos Besoins",
            description1: "Je suis à votre écoute et vous conseille pour répondre à vos besoins. Mon objectif est de comprendre en profondeur ce que vous recherchez, vos objectifs et la vision que vous avez pour votre site internet. N'hésitez pas à partager vos idées, aspirations et attentes.",

         },
      },
      custom: {
         title: "Codes personnalisés",
         description: "En tant que développeur Front-End, je possède une maîtrise approfondie des fondamentaux du web, avec une expertise particulière dans les technologies suivantes ",
         section1: {
            title: "Développeur Front-End",
            subtitle1: "HTML, CSS, JavaScript",
            description1: "Mes compétences s'étendent à la création de pages web dynamiques et interactives en utilisant les langages de base du web",
            subtitle2: "Applications à page unique (SPA)",
            description2: "J'ai une expérience approfondie dans la création de Single Page Applications en utilisant des frameworks modernes tels que React, ainsi que la gestion de la navigation avec React Router.",
            subtitle3: "Redux",
            description3: "Maîtrise de la gestion d'état avec Redux, assurant une gestion fluide des données au sein de l'application.",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
         section2: {
            title: "Outils de Développement",
            subtitle1: "Git-Hub ",
            description1: "Utilisation avancée de GitHub pour la gestion efficace des versions et la collaboration sur les projets.",
            subtitle2: "VS Code",
            description2: "En tant qu'éditeur de code principal, je travaille efficacement avec Visual Studio Code pour un développement rapide et efficace.",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
         section3: {
            title: "Contactez-Moi",
            subtitle1: "",
            description1: "Si vous avez un projet en tête, n'hésitez pas à me contacter. Je suis ouvert à discuter de vos idées, à comprendre vos besoins et à trouver la meilleure solution pour donner vie à votre vision.",
            subtitle2: "",
            description2: "",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
         section4: {
            title: "",
            subtitle1: "",
            description1: "",
            subtitle2: "",
            description2: "",
            subtitle3: "",
            description3: "",
            subtitle4: "",
            description4: "",
            subtitle5: "",
            description5: "",
         },
      }
   }


}